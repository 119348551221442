<div class="checkbox" [class.disabled]="disabled()">
  <label>
    <input
      type="checkbox"
      [checked]="value"
      [(ngModel)]="value"
      [tabIndex]="tabIndex()"
      (blur)="onBlur()"
      [disabled]="disabled() || readOnly()"
    />
    <span class="box">
      <pn-icon icon="checkbox"></pn-icon>
    </span>
  </label>
  <div class="label" [class.hidden]="hideLabel" #label>
    <ng-content></ng-content>
  </div>
</div>
@if (isTouched) {
  <ng-content select="pn-error"></ng-content>
}
