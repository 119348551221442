import {
  AfterContentInit,
  Component,
  ContentChildren,
  QueryList,
  Renderer2,
} from '@angular/core';
import { CheckboxComponent } from '@shared/components/form-elements/checkbox/components/checkbox/checkbox.component';

@Component({
  selector: 'pn-checkbox-list',
  template: '<ng-content></ng-content>',
  host: {
    class: 'checkbox-list',
  },
})
export class CheckboxListComponent implements AfterContentInit {
  @ContentChildren(CheckboxComponent) checkboxes!: QueryList<CheckboxComponent>;
  constructor(private renderer: Renderer2) {}
  ngAfterContentInit() {
    this.checkboxes.forEach((component) => {
      this.renderer.addClass(component.el.nativeElement, 'no-margin-top');
    });
  }
}
