/* src/app/_shared/components/form-elements/checkbox/components/checkbox/checkbox.component.scss */
:root {
  --icon-fill: #000;
  --icon-stroke: #000;
}
:host {
  margin-top: 20px;
  display: block;
}
:host:first-child,
:host.no-margin-top {
  margin-top: 0;
}
:host.readonly {
  cursor: default;
  pointer-events: none;
}
.checkbox {
  display: inline-flex;
  align-items: center;
  gap: 13px;
  position: relative;
}
.checkbox.disabled {
  opacity: 0.3;
  pointer-events: none;
}
label {
  display: block;
  margin-bottom: 0;
  position: relative;
}
input {
  position: absolute;
  opacity: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  cursor: pointer;
}
.box {
  width: 20px;
  height: 20px;
  flex: 0 0 20px;
  border-radius: 8px;
  border: solid 1px #ced2da;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease, border-color 0.3s ease;
  --icon-fill: #fff;
}
.box pn-icon {
  opacity: 0;
  transition: opacity 0.3s ease;
}
input:checked ~ .box {
  background-color: #338054;
  border-color: #338054;
}
input:checked ~ .box pn-icon {
  opacity: 1;
}
input:focus-visible ~ .box {
  outline: #338054 auto 1px;
  outline-offset: 5px;
}
.label {
  font-size: 12px;
  line-height: 14px;
  color: #212121;
  padding-top: 1px;
}
.label.hidden {
  display: none;
}
.label a {
  color: #338054;
  text-decoration: underline;
}
.label a:hover {
  color: #338054;
  text-decoration: none;
}
/*# sourceMappingURL=checkbox.component.css.map */
