import {
  AfterContentInit,
  Component,
  ElementRef,
  forwardRef,
  HostBinding,
  input,
  model,
  viewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'pn-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrl: './checkbox.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckboxComponent),
      multi: true,
    },
  ],
})
export class CheckboxComponent
  implements ControlValueAccessor, AfterContentInit
{
  private _value: boolean = false;
  /* eslint-disable @typescript-eslint/no-empty-function */
  private onChange: (value: boolean) => void = () => {};
  private onTouched: () => void = () => {};
  /* eslint-enable @typescript-eslint/no-empty-function */
  hideLabel: boolean = true;
  isTouched: boolean | null = false;

  labelRef = viewChild<ElementRef>('label');
  disabled = model<boolean>(false);
  tabIndex = input<number>(0);
  readOnly = input<boolean>(false);

  @HostBinding('class.readonly') get readonly() {
    return this.readOnly();
  }

  constructor(public el: ElementRef) {}

  ngAfterContentInit() {
    this.hideLabel = !this.labelRef()?.nativeElement.innerHTML.trim();
  }

  get value(): boolean {
    return this._value;
  }

  set value(v: boolean) {
    if (v !== this._value) {
      this._value = v;
      this.onChange(v);
    }
  }

  writeValue(value: boolean) {
    this.value = value;
  }

  registerOnChange(fn: (value: boolean) => void) {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void) {
    this.onTouched = fn;
  }

  setDisabledState?(disabled: boolean): void {
    this.disabled.set(disabled);
  }

  onBlur() {
    this.isTouched = true;
    this.onTouched();
  }
}
