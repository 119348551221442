import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconComponent } from '@shared/components/base/icon/icon.component';
import { FormsModule } from '@angular/forms';
import { CheckboxComponent } from '@shared/components/form-elements/checkbox/components/checkbox/checkbox.component';
import { CheckboxListComponent } from '@shared/components/form-elements/checkbox/components/checkbox-list.component';

@NgModule({
  declarations: [CheckboxComponent, CheckboxListComponent],
  imports: [CommonModule, IconComponent, FormsModule],
  exports: [CheckboxComponent, CheckboxListComponent],
})
export class CheckboxModule {}
